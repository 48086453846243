<template>
  <div
    :id="random_id"
    :data-testid="props.dataTestid"
    class="bg-white rounded-xl"
  >
    <TsRow append-class="m-0 p-4 bg-natural-soft-white">
      <TsColumn append-class="p-0 is-2 md:is-1  flex items-center ms-2">
        <NuxtImg
          v-if="props.mainProduct && props.mainProduct.image"
          class="rounded-lg"
          :data-testid="baseDataTestid + 'parent-product-preview-image'"
          :src="props.mainProduct.image"
          alt="product"
          width="49"
          height="49"
        />
      </TsColumn>
      <TsColumn append-class="p-0 is:9 md:is-10">
        <TsTypography append-class="text-idle-black leading-6">
          {{ useTranslation("variantsOf", "Varianten van") }}
        </TsTypography>
        <TsRow append-class="m-0 pr-7">
          <TsTypography
            v-if="props.mainProduct?.full_name"
            :data-testid="baseDataTestid + 'parent-product-full-name'"
            append-class="mb-0 text-idle-black"
          >
            <span class="font-bold">{{
              props.mainProduct?.full_name.split(" ")[0]
            }}</span>
            {{ props.mainProduct?.full_name?.split(" ").slice(1).join(" ") }}
          </TsTypography>
        </TsRow>
      </TsColumn>
      <TsColumn append-class="p-0 is-1">
        <TsIcon
          name="bitcoin-icons:cross-filled"
          @click="emit('close')"
          :data-testid="baseDataTestid + 'close-icon'"
          class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
        />
      </TsColumn>
    </TsRow>

    <div
      class="overflow-y-auto max-h-[28rem] h-full relative bg-natural-soft-white"
    >
      <!-- variant loader -->
      <template v-if="!props.variants.length">
        <TsCard
          v-for="n in 2"
          :key="n"
          :data-testid="baseDataTestid + 'fetch-loader-' + n"
          append-class="p-3 bg-idle-white animate-pulse"
          no-shadow
          no-border
        >
          <div class="flex justify-between items-center mb-1">
            <div class="h-5 bg-blue-300/70 rounded-lg w-48"></div>
            <div class="h-5 bg-blue-300/70 rounded-lg w-28"></div>
          </div>

          <div class="h-5 bg-blue-300/70 rounded-lg w-36 mb-1"></div>
          <div class="h-2 bg-blue-300/70 rounded-lg w-20 mt-2"></div>

          <div class="mt-4 h-10 bg-blue-300/70 rounded-lg mx-10"></div>
        </TsCard>
      </template>

      <!-- variant details -->
      <template v-else>
        <TsCard
          v-for="variant in props.variants"
          :key="variant.code"
          :data-testid="baseDataTestid + `variant-${variant.code}-card`"
          append-class="bg-white m-4 p-3"
          no-shadow
          no-border
        >
          <TsRow append-class="justify-between m-0">
            <TsColumn append-class="p-0">
              <TsTypography
                as="h2"
                :data-testid="baseDataTestid + `variant-${variant.code}-name`"
                append-class="text-idle-black leading-6 mb-1"
                >{{ variant.pack_size || variant.name }}
                <small
                  class="text-natural-grey"
                  :data-testid="baseDataTestid + `variant-${variant.code}-code`"
                >
                  ({{ variant.code }})
                </small></TsTypography
              >
            </TsColumn>
            <TsColumn append-class="p-0 grow-0">
              <TsTag
                v-if="stockStore.isProductOutOfStock(variant)"
                label="Out of stock"
                :data-testid="
                  baseDataTestid + `variant-${variant.code}-out-of-stock-tag`
                "
                variant="danger"
                rounded
                append-class="py-1"
                size="sm"
              />
              <TsTag
                v-else
                :label="getMaximumStock(variant) + ' Available'"
                :data-testid="
                  baseDataTestid + `variant-${variant.code}-maximum-stock-tag`
                "
                variant="info"
                rounded
                append-class="py-1"
                size="sm"
              />
            </TsColumn>
          </TsRow>

          <TsRow append-class="m-0 gap-1">
            <div
              :class="`variant.prices?.raw.gross < variant.prices.was_price_raw && h-4`"
            >
              <TsTypography
                size="sm"
                :data-testid="baseDataTestid + `variant-${variant.code}-price`"
                v-if="
                  variant?.prices &&
                  variant?.prices?.raw &&
                  variant?.prices?.was_price_formatted &&
                  variant?.prices?.raw.gross < variant?.prices.was_price_raw
                "
                append-class="line-through leading-4 m-0"
              >
                {{ `${variant?.prices?.was_price_formatted}` }}
              </TsTypography>
            </div>

            <TsTypography
              v-if="variant?.prices?.formatted.discount_percentage !== '0%'"
              :data-testid="baseDataTestid + `variant-${variant.code}-discount`"
              size="sm"
              weight="bold"
              append-class="text-primary leading-4"
            >
              ({{ `${variant.prices?.formatted.discount_percentage} OFF` }})
            </TsTypography>
          </TsRow>

          <TsTypography
            variant="h2"
            weight="bold"
            append-class="text-idle-black mb-0 leading-6"
            :data-testid="baseDataTestid + `variant-${variant.code}-vat-price`"
          >
            {{
              rootStore.isIncVat
                ? variant?.prices?.formatted?.gross
                : variant?.prices?.formatted?.net
            }}

            <TsTypography
              v-if="variant.prices?.formatted.per_unit"
              as="span"
              variant="xs"
              append-class="text-natural-silver-grey leading-tight"
              :data-testid="
                baseDataTestid + `variant-${variant.code}-unit-price`
              "
            >
              {{ variant.prices?.formatted.per_unit }}
            </TsTypography>
          </TsTypography>

          <TsTypography variant="xs" append-class="leading-4 text-natural-grey">
            {{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
            {{
              rootStore.isIncVat
                ? variant?.prices?.formatted?.net
                : variant?.prices?.formatted?.gross
            }}
          </TsTypography>

          <template v-if="stockStore.isProductOutOfStock(variant)">
            <TsButton
              v-if="isSubscribedToStock(variant)"
              icon="material-symbols:unsubscribe-outline"
              :label="unsubscribeLabel"
              :data-testid="
                baseDataTestid + `variant-${variant.code}-stock-unsubscribe-cta`
              "
              :is-loading="isStockUnsubscribeCTAloading"
              @click="handleStockUnsubscribe(variant.code)"
              append-class="mt-2"
              outlined
              block
            />
            <TsButton
              v-else
              icon="mdi:bell-outline"
              :label="notifyMeLabel"
              :data-testid="
                baseDataTestid + `variant-${variant.code}-stock-notify-cta`
              "
              @click="emit('notify', variant)"
              append-class="mt-2"
              block
            />
          </template>

          <TsButton
            v-else
            :label="selectCTALabel"
            :data-testid="
              baseDataTestid + `variant-${variant.code}-select-variant-cta`
            "
            @click="emit('select', variant)"
            append-class="mt-2"
            block
          />
        </TsCard>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Product } from "~/types/ecom/product/product.type";
import { data as dummyProduct } from "~/json/product.json";
import { useTranslation } from "~/composables/useTranslation";

const stockStore = useStockStore();
const rootStore = useRootStore();

type Props = {
  mainProduct?: Product;
  variants?: Product[];
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  mainProduct: () => dummyProduct,
  variants: () => [dummyProduct],
});

const emit = defineEmits<{
  close: any;
  notify: [selectedVariant: Product];
  select: [selectedVariant: Product];
}>();

const isStockUnsubscribeCTAloading = ref(false);

const baseDataTestid = "product-variants-modal-";

const unsubscribeLabel = useTranslation("unsubscribe", "Uitschrijven");
const notifyMeLabel = useTranslation("notifyMe", "Breng mij op de hoogte");
const selectCTALabel = useTranslation("select", "Selecteren");

function isSubscribedToStock(variant: Product) {
  const user: any = useAuthStore().user;
  if (!user || !user.id) return false;

  return useAccountStore().stock_notifications.some(
    (notification: any) => notification.product_id === variant.code
  );
}

async function handleStockUnsubscribe(productCode: string) {
  isStockUnsubscribeCTAloading.value = true;
  await useAuthStore().onStockUnsubscribe(productCode);
  isStockUnsubscribeCTAloading.value = false;
}

const getMaximumStock = (variant: Product) => {
  const collection = variant.stockDetails?.collection || 0;
  const delivery = variant.stockDetails?.delivery || 0;
  const maximumStock = Math.max(collection, delivery);

  return maximumStock > 20 ? "20+" : maximumStock.toString();
};

const random_id = ref("");
onMounted(() => {
  random_id.value = useRandomUUID();
});
</script>
